import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import { theme as ourTheme } from "../theme";
import Seo from "../components/seo";
import { isDefined, isValidLength, isValidMail } from "../utils/validations";
import Mail from "../services/MailService";
import logoPositive from "../images/logo.png";

const metadata = {
  title: "Allfunds Data Analytics",
  description: "Targeting Money Market dry powder with Allfunds Navigator",
};

const ADA_SUPPORT_EMAIL = "adasupport@allfunds.com";

const videoLink =
  "https://app.allfunds.com/docs/cms/a835c90bc6606c210c66e80fee27b9e791db38e5_7f4d8ec849.mp4";
const bgImg =
  "https://app.allfunds.com/docs/cms/allfunds_navigator_84c0b6d961.png";

function AllfundsDataAnalytics() {
  const { locale } = useLocalization();
  const [data, setData] = useState({
    subject: "Request ADA Navigator",
    product: "request-ada",
    type: "product",
    from: "noreply@allfunds.com",
    hasTemplate: true,
    sendTicket: false,
  });
  const [isSent, setIsSent] = React.useState(false);

  const sendRequest = () => {
    Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      if (status === 200) {
        //alert("Form Send");
        setIsSent(true);
      }
    });
  };

  const handleNameChange = (value) => {
    setData({ ...data, templateData: { ...data.templateData, name: value } });
  };

  const handleCompanyChange = (value) => {
    setData({ ...data, templateData: { ...data.templateData, company: value } });
  };

  const handleEmailChange = (value) => {
    setData({ ...data, to: [ADA_SUPPORT_EMAIL, value], templateData: { ...data.templateData, email: value } });
  };

  const validate = () => {
    const emailValid = isDefined(data?.templateData?.email) && isValidMail(data?.templateData?.email) == null;
    const nameValid =
      isDefined(data?.templateData?.name) && isValidLength(data?.templateData?.name, 3) == null;

    return emailValid && nameValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendRequest();
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  const form = () => {
    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ marginLeft: { xs: "-24px", md: 0 } }}
      >
        <Stack spacing={2}>
          <Stack spacing={2}>
            <TextField
              label={<Trans>Full name</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleNameChange(e.target.value)}
              error={isValidLength(data.name, 3)}
              helperText={isValidLength(data.name, 3)}
              value={data.name}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
            <TextField
              label={<Trans>Your Company</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleCompanyChange(e.target.value)}
              error={isValidLength(data.company, 3)}
              helperText={isValidLength(data.company, 3)}
              value={data.company}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
            <TextField
              label={<Trans>Email</Trans>}
              variant="outlined"
              fullWidth
              onChange={(e) => handleEmailChange(e.target.value)}
              error={isValidMail(data.email)}
              helperText={isValidMail(data.email)}
              value={data.email}
              required
              focused
              color="inherit"
              sx={{ input: { color: "white" } }}
            />
          </Stack>
          <Stack>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              sx={{
                height: "50px",
                width: "100%",
              }}
              type="submit"
              disabled={!validate()}
            >
              <Typography
                variant="info"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white !important",
                }}
              >
                <Trans>Book your demo</Trans>
              </Typography>
            </Button>
          </Stack>
          <Typography fontSize="12px" lineHeight="1.1" p={0}>
            <Trans>
              By submitting this form you agree we might contact you only for
              the purpose of following up on your interest in this topic.
            </Trans>
          </Typography>
        </Stack>
      </Box>
    );
  };

  const sendMessage = () => {
    return (
      <Stack spacing={2} textAlign="center" sx={{ marginLeft: { xs: "-24px", md: 0 } }}>
        <Typography variant="h2">
          <Trans>Thank you!</Trans>
        </Typography>
        <Typography>
          <Trans>
            If you would like further information, please don’t
            hesitate to contact us
          </Trans>
          <a href="mailto:adasupport@allfunds.com"> {ADA_SUPPORT_EMAIL}</a>
        </Typography>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={ourTheme}>
      <CssBaseline />
      <StyledContainer>
        <video id="vid" autoPlay muted loop poster={bgImg}>
          <source src={videoLink} type="video/mp4" />
        </video>
        <Seo title={metadata.title} description={metadata.description} />
        <Box component="header" px={{ xs: 2, sm: 5 }} py={4}>
          <Link className="logo" to="/">
            <img alt="allfunds_logo" src={logoPositive} />
          </Link>
        </Box>
        <Stack
          component="section"
          px={{ xs: 2, sm: 5 }}
          pb={4}
          spacing={3}
          minHeight="100dvh"
        >
          <Box component="header">
            <Typography variant="h1">
              <Trans>Allfunds Data Analytics</Trans>
            </Typography>
            <Typography variant="h2">
              <Trans>
                Targeting Money Market dry powder with Allfunds Navigator
              </Trans>
            </Typography>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7} px="0!important" py="0!important">
              <Typography>
                <Trans>
                  <strong>Fund providers</strong> can unlock a massive flow
                  opportunity by <strong> helping clients find relevant alternatives to Money Market funds
                  </strong> as interest rates decrease and investors expect allocations to resulting return opportunities to justify their fees.
                </Trans>
              </Typography>
              <Stack spacing={2} mt={2}>
                <Typography variant="h3">
                  <Trans>Massive opportunity, but where do I start?</Trans>
                </Typography>
                <ul>
                  <li>
                    <Trans>
                      To be sure, <strong>some institutional fund buyers have already reduced </strong>
                      their Money Market exposure by an average of 20% this year
                      up to Q3. While there are still plenty of both retail and
                      institutional buyers with large Money Market allocation as
                      targets, you have to be quick to ensure they favour you in
                      their reallocation decisions.
                    </Trans>
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    <Trans>
                      You can count on a <strong>Money Market holdings pool worth over €50bn </strong>
                      if those investors who haven’t yet reduced their holdings
                      also deploy around 20% of their Money Market dry powder
                      over the next few months.
                    </Trans>
                  </li>
                </ul>
              </Stack>
              <Stack spacing={2} mt={2}>
                <Typography variant="h3">
                  <Trans>How can Allfunds help?</Trans>
                </Typography>
                <Typography>
                  <Trans>
                    By leveraging <strong>unique open architecture datasets(1)</strong>, the
                    <strong> Allfunds Data Analytics</strong> tools are designed
                    to help you discover where to find the right clients, who
                    they are and what they are allocating to. If you had access
                    to these tools today:
                  </Trans>
                </Typography>
                <ul>
                  <li>
                    <Trans>
                      You would see that significant dry powder <strong>opportunities currently
                        exist with asset management companies in the UK and France, as well as with Spanish
                        banks and Swedish insurers,</strong> amongst others.
                    </Trans>
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    <Trans>
                      You would also find<strong> specific distributor and portfolio manager names.</strong>
                    </Trans>
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    <Trans>
                      <strong>Interested in partnering with Europe’s best platform for
                        market intelligence according to fund providers(2)? </strong>Get in touch to book your demo now!
                    </Trans>
                  </li>
                </ul>
              </Stack>
              <ol style={{ fontSize: "12px", padding: 0, lineHeight: 1.1 }}>
                <li>
                  Spanning from the Allfunds global distribution network, EMEA
                  and APAC fund-in-funds and EMEA sub-advised funds.
                </li>
                <li>
                  Source: Platforum European Fund Distribution: Platforms (May
                  2024).
                </li>
              </ol>
            </Grid>
            <Grid item xs={12} md={5}>
              {isSent ? sendMessage() : form()}
            </Grid>
          </Grid>
        </Stack>
      </StyledContainer>
    </ThemeProvider>
  );
}

const StyledContainer = styled(Container)(
  `
  max-width: unset!important;
  width: 100vw;
  min-height: 100dvh;
  background: linear-gradient(90deg, rgba(27,54,93,1) 0%, rgba(126,5,5,0) 100%);
  color: #fff;
  p {color: #fff;}
  #vid{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }
`,
);

export default AllfundsDataAnalytics;
